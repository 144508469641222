@import '../../../../../or_base/scss/theme-bootstrap';

// Live Chat button style for PC SPP Pages.
.page-product {
  .rounded-live-chat-button {
    bottom: 60px;
    @include breakpoint($large-up) {
      bottom: 90px;
    }
  }
  .lc-service-drawer {
    &-v1 {
      @include breakpoint($large-up) {
        bottom: calc(90px + 4em);
      }
    }
  }
}

// Live Chat button style for Mobile Checkout Pages.
.viewcart,
.checkout {
  .rounded-live-chat-button {
    bottom: 90px;
    @include breakpoint($large-up) {
      bottom: 30px;
    }
  }
}

.confirm,
.checkout {
  &.active-panel-signin,
  &.active-panel-review,
  &.active-panel-registration {
    .rounded-live-chat-button {
      bottom: 30px;
    }
  }
}

.samples {
  .rounded-live-chat-button {
    bottom: 80px;
    @include breakpoint($large-up) {
      bottom: 30px;
    }
  }
}

.lc-service-drawer {
  &-v1 {
    #{$rdirection}: 0;
    background: $color-white;
    font-size: 16px;
    bottom: 0;
    box-shadow: 0 0 4px $color-black;
    display: none;
    position: fixed;
    width: 100%;
    z-index: 99999;
    @include breakpoint($large-up) {
      #{$rdirection}: 5px;
      bottom: calc(30px + 4em);
      width: 400px;
    }
  }
  &__header-text {
    color: $color-white;
  }
  &__header-container {
    background: $color-black;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    [dir='rtl'] & {
      flex-direction: row-reverse;
    }
  }
  .device-mobile &__header-text,
  &__header-text {
    color: $color-white;
    font-size: 25px;
    font-weight: 400;
    font-family: $font--futura-demi;
    margin: 0 15px;
    text-transform: inherit;
  }
  &__body-container {
    font-weight: 400;
    font-family: $font--futura-book;
    padding: 15px;
    .lc-service-drawer {
      &__button {
        background: $color-black;
        color: $color-white;
        cursor: pointer;
        display: block;
        font-size: 15px;
        font-weight: 200;
        margin: 10px 0;
        padding: 10px;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        width: 100%;
        &:hover {
          background: $color-green;
        }
      }
    }
  }
  &__logo {
    background: url('/media/images/global/origins_logo_white.png') no-repeat 0 50%;
    background-size: cover;
    height: 2em;
    width: 11em;
    @include breakpoint($large-up) {
      width: 9em;
    }
  }
  &__overlay {
    display: none;
    position: fixed;
    top: 0;
    @media #{$small-down} {
      height: 100%;
      width: 100%;
      z-index: 9990;
    }
  }
}

.rounded-live-chat-button {
  #{$rdirection}: 5px;
  align-items: center;
  background-color: $color-black;
  border: none;
  border-radius: 2em;
  bottom: 60px;
  color: $color-white;
  font-family: $font--futura-book;
  font-size: 16px;
  cursor: pointer;
  display: none;
  justify-content: center;
  padding: 1em;
  position: fixed;
  z-index: 1000;
  @include breakpoint($large-up) {
    bottom: 30px;
  }
  &:hover {
    background: $color-green;
  }
  &__down-caret {
    border-#{$rdirection}: 2px solid $color-white;
    border-bottom: 2px solid $color-white;
    height: 0.5em;
    margin: 0 10px 5px;
    transform: rotate(45deg);
    width: 0.5em;
  }
}

.LPMcontainer,
.helpButton {
  visibility: hidden;
}

.lp_desktop {
  #lpChat {
    .lp_maximized {
      &.lpmx {
        #{$ldirection}: auto;
      }
    }
  }
}
